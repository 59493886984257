import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SharedService } from 'src/app/services/shared.service';
import { NodeComponent } from '../node/node.component';

@Component({
  selector: 'app-sem-node',
  templateUrl: './sem-node.component.html',
  styleUrls: ['../node/node.component.css', './sem-node.component.css']
})
export class SemNodeComponent extends NodeComponent {

  constructor(protected route: ActivatedRoute, protected router: Router, protected sharedService: SharedService, protected localizeService: LocalizeRouterService) {
    super(route, router, sharedService, localizeService);
  }

  get prefix(): string {
    return "sem";
  }

}
