<div class="swiper-container swiper-container-{{prefix}}">
  <div class="swiper-wrapper">
    <div class="swiper-slide enlight-yellow-node">
      <main>
        <div class="main-wrapper">
          <section class="items">
            <div class="item item-bbt first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-bbt-replay' : 'item-bbt-play'">
              <h4><span class="sequence-number">0</span>{{'bbt-node.subtitle' | translate}}</h4>
            </div>
            <div class="item item-node-1" (click)="navigateToInternalNode(1)">
              <h4><span class="sequence-number">1</span>{{'bbt-node.slide-1-title' | translate}}</h4>
            </div>
            <div class="item item-node-2" (click)="navigateToInternalNode(2)">
              <h4><span class="sequence-number">2</span>{{'bbt-node.slide-2-title' | translate}}</h4>
            </div>
            <div class="item item-node-3" (click)="navigateToInternalNode(3)">
              <h4><span class="sequence-number">3</span>{{'bbt-node.slide-3-title' | translate}}</h4>
            </div>
            <div class="item item-node-4" (click)="navigateToInternalNode(4)">
              <h4><span class="sequence-number">4</span>{{'bbt-node.slide-4-title' | translate}}</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide enlight-yellow-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-1">
              <h3 (click)="navigateToInternalNode(0)">{{'bbt-node.subtitle' | translate}}</h3>
              <h1>{{'bbt-node.slide-1-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/bbt/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-enlight-yellow.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'bbt-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'bbt-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h6>{{'bbt-node.slide-1-meet-the-expert' | translate}}</h6>
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/09/Meet_the_Experts_Designing_a_space_mission#.YA7QW-P8oaY.link" target="_blank">{{'bbt-node.slide-1-design-space-mission' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://www.esa.int/Enabling_Support/Space_Engineering_Technology/CDF" target="_blank">{{'bbt-node.slide1-cuncurrent-design-facility' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide enlight-yellow-node mixed-with-enlight-yellow">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-2">
              <h3 (click)="navigateToInternalNode(0)">{{'bbt-node.subtitle' | translate}}</h3>
              <h1>{{'bbt-node.slide-2-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/bbt/p2.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-enlight-yellow.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'bbt-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'bbt-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2019/06/Ariane_6_at_Europe_s_Spaceport#.YA7Q0VHbShI.link" target="_blank">Ariane 6 </a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Enabling_Support/Space_Transportation/Vega" target="_blank">Vega</a></h4>
            </div>
            <div class="item item-image">
              <h4><a href="http://www.esa.int/var/esa/storage/images/esa_multimedia/images/2019/05/vega-c_features/19420921-1-eng-GB/Vega-C_features_pillars.jpg" target="_blank">Vega-C</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2019/06/Space_Rider_animation#.YA7RNA6ezAg.link" target="_blank">{{'bbt-node.slide-2-space-rider' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esahq' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA HQ</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/ariane6' | localize]">
              <div class="extra-label is-extra-label">{{'bbt-node.join-in' | translate}}</div>
              <h6 class="extra-title is-extra-title">{{'bbt-node.ariane6-title' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide enlight-yellow-node mixed-with-enlight-yellow">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-3">
              <h3 (click)="navigateToInternalNode(0)">{{'bbt-node.subtitle' | translate}}</h3>
              <h1>{{'bbt-node.slide-3-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/bbt/p3.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-enlight-yellow.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'bbt-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'bbt-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2019/06/Ariane_6_at_Europe_s_Spaceport#.YA7Q0VHbShI.link" target="_blank">Ariane 6</a></h4>
            </div>
            <div class="item item-image">
              <h4><a href="http://www.esa.int/var/esa/storage/images/esa_multimedia/images/2019/05/vega-c_features/19420921-1-eng-GB/Vega-C_features_pillars.jpg" target="_blank">Vega-C</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esahq' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA HQ</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/ariane6' | localize]">
              <div class="extra-label is-extra-label">{{'bbt-node.join-in' | translate}}</div>
              <h6 class="extra-title is-extra-title">{{'bbt-node.ariane6-title' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide enlight-yellow-node mixed-with-trusty-azure">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-4">
              <h3 (click)="navigateToInternalNode(0)">{{'bbt-node.subtitle' | translate}}</h3>
              <h1>{{'bbt-node.slide-4-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/bbt/p4.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-enlight-yellow.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'bbt-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'bbt-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/09/ESOC_music_video#.YA7SILbCq58.link" target="_blank">{{'bbt-node.slide-4-welcome-to' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2015/09/Ground_station_chillax#.X8eYs6z4UyE.link" target="_blank">{{'bbt-node.slide-4-ground-station' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://estracknow.esa.int" target="_blank">{{'bbt-node.slide-4-live-tracking-data' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.youtube.com/watch?v=xhM-bqXTKoU " target="_blank">{{'bbt-node.slide-4-the-burn' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/flightdynamics" target="_blank">{{'bbt-node.slide-4-flight-dynamics' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Sets/Paolo_Ferri_-_master_class/(result_type)/videos" target="_blank">{{'bbt-node.slide-4-paolo-ferri' | translate}}</a></h4>
            </div>
            <div class="item item-image">
              <h4><a href="https://www.esa.int/Enabling_Support/Operations/ESA_Ground_Stations/ESA_Ground_Stations_Live" target="_blank">{{'bbt-node.slide-4-estrack-webcam-gallery' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esoc' | localize]">
              <div class="extra-label vt-extra-label">{{'bbt-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESOC</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
  </div>
  <div class="swiper-button-prev">
    <img src="assets/icons/left-white.svg">
  </div>
  <div class="swiper-button-next">
    <img src="assets/icons/right-white.svg">
  </div>
</div>
