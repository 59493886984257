<div class="swiper-container swiper-container-{{prefix}}">
  <div class="swiper-wrapper">
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper">
          <section class="items">
            <div class="item item-ile first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-ile-replay' : 'item-ile-play'">
              <h4><span class="sequence-number">0</span>{{'ile-node.subtitle' | translate}}</h4>
            </div>
            <div class="item item-node-1" (click)="navigateToInternalNode(1)">
              <h4><span class="sequence-number">1</span>{{'ile-node.slide-1-title' | translate}}</h4>
            </div>
            <div class="item item-node-2" (click)="navigateToInternalNode(2)">
              <h4><span class="sequence-number">2</span>{{'ile-node.slide-2-title' | translate}}</h4>
            </div>
            <div class="item item-node-3" (click)="navigateToInternalNode(3)">
              <h4><span class="sequence-number">3</span>{{'ile-node.slide-3-title' | translate}}</h4>
            </div>
            <div class="item item-node-4" (click)="navigateToInternalNode(4)">
              <h4><span class="sequence-number">4</span>{{'ile-node.slide-4-title' | translate}}</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-1">
              <h3 (click)="navigateToInternalNode(0)">{{'ile-node.subtitle' | translate}}</h3>
              <h1>{{'ile-node.slide-1-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ile/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ile-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ile-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Telecommunications_Integrated_Applications" target="_blank">{{'ile-node.slide-1-telecommunications' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://www.esa.int/Applications/Telecommunications_Integrated_Applications/Space_promotes_economic_growth" target="_blank">{{'ile-node.slide-1-space-for-economy' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/11/Moonlight_connecting_Earth_with_the_Moon#.YAsMXeJ5ABo.link" target="_blank">{{'ile-node.slide-1-moonlight' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node mixed-with-excite-red mixed-with-trusty-azure">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-2">
              <h3 (click)="navigateToInternalNode(0)">{{'ile-node.subtitle' | translate}}</h3>
              <h1>{{'ile-node.slide-2-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ile/p2.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ile-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ile-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/11/Moonlight_connecting_Earth_with_the_Moon#.YAsMXeJ5ABo.link" target="_blank">{{'ile-node.slide-2-moonlight' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://commercialisation.esa.int/bic" target="_blank">{{'ile-node.slide-2-bic' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://business.esa.int/" target="_blank">{{'ile-node.slide-2-space-solutions' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h6>{{'ile-node.meet-the-experts' | translate}}</h6>
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/07/Meet_the_Experts_Satellite_Navigation#.YAsNALeIF8k.link" target="_blank">{{'ile-node.slide-2-satellite-navigation' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h6>{{'ile-node.get-involved' | translate}}</h6>
              <h4><a href="https://www.esa.int/Education/CubeSats_-_Fly_Your_Satellite/Fly_Your_Satellite!_programme" target="_blank">{{'ile-node.slide-2-fly-your-satellite' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esac' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESAC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esec' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESEC</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
            <div class="extra-trigger trusty-azure-extra-trigger" (click)="navigateToOtherNodes('ssa', 0)">
              <div class="extra-label trusty-azure-label">{{'ile-node.safeguarding-space-activities' | translate}}</div>
              <h6 class="extra-title trusty-azure-title">{{'ile-node.go-to-this-journey' | translate}}</h6>
            </div>
            <div class="extra-trigger excite-red-extra-trigger" (click)="navigateToOtherNodes('sem', 0)">
              <div class="extra-label excite-red-label">{{'ile-node.sending-europe-to-the-moon' | translate}}</div>
              <h6 class="extra-title excite-red-title">{{'ile-node.go-to-this-journey' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-3">
              <h3 (click)="navigateToInternalNode(0)">{{'ile-node.subtitle' | translate}}</h3>
              <h1>{{'ile-node.slide-3-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ile/p3.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ile-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ile-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/12/ESA_5G_6G_Hub_space_to_expand_connectivity" target="_blank">{{'ile-node.slide-3-5g-6g-hub' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/12/What_is_5G#.YA_vtgyd5Z8.link" target="_blank">{{'ile-node.slide-3-what-is-5g' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2018/05/Satellite_for_5G#.YBArpnLPlup.link" target="_blank">{{'ile-node.slide-3-satellites-for-5g' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node mixed-with-trusty-azure">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-4">
              <h3 (click)="navigateToInternalNode(0)">{{'ile-node.subtitle' | translate}}</h3>
              <h1>{{'ile-node.slide-4-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/ile/p4.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'ile-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'ile-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2021/01/EDRS_the_space_data_highway" target="_blank">{{'ile-node.slide-4-edrs' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2015/10/Saving_lives_when_disasters_strike#.YAsOQBmWlko.link" target="_blank">{{'ile-node.slide-4-international-charter-space' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'ile-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger trusty-azure-extra-trigger" (click)="navigateToOtherNodes('ssa', 0)">
              <div class="extra-label trusty-azure-label">{{'ile-node.safeguarding-space-activities' | translate}}</div>
              <h6 class="extra-title trusty-azure-title">{{'ile-node.go-to-this-journey' | translate}}</h6>
            </div>
            <div class="extra-trigger pure-teal-extra-trigger" (click)="navigateToOtherNodes('poe', 0)">
              <div class="extra-label pure-teal-label">{{'ile-node.protecting-our-environment' | translate}}</div>
              <h6 class="extra-title pure-teal-title">{{'ile-node.go-to-this-journey' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
  </div>
  <div class="swiper-button-prev">
    <img src="assets/icons/left-white.svg">
  </div>
  <div class="swiper-button-next">
    <img src="assets/icons/right-white.svg">
  </div>
</div>
