import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  get bodyElement() {
    return document.getElementsByTagName("body")[0];
  }

  showMenu() {
    this.sharedService.menuOpen = true;
    this.bodyElement.classList.add("show-menu");
  }

  hideMenu() {
    this.sharedService.menuOpen = true;
    this.bodyElement.classList.remove("show-menu");
  }

  goToESAWebsite() {
    window.open('https://www.esa.int');
  }

  goToVideo(prefix: string, index: number) {
    this.sharedService.fadeOutAndNavigateTo(["/video", prefix, index]);
  }
  
  goToHomeVideo() {
    this.sharedService.lastIndex = -1;
    this.sharedService.lastPrefix = "";
    this.sharedService.homeVideo = true;
    this.sharedService.fadeOutAndNavigateTo(["/video", "home", 0]);
  }
  
  goToWelcomeVideo() {
    this.sharedService.lastIndex = -1;
    this.sharedService.lastPrefix = "";
    this.sharedService.homeVideo = true;
    this.sharedService.fadeOutAndNavigateTo(["/video", "home", 1]);
  }

}
