import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { VideoComponent } from './components/video/video.component';
import { NodeFactoryComponent } from './components/node-factory/node-factory.component';
import { IsFactoryComponent } from './components/is-factory/is-factory.component';
import { VtComponent } from './components/vt/vt.component';
import { Ariane6IsComponent } from './components/ariane6-is/ariane6-is.component';
import { EoIsComponent } from './components/eo-is/eo-is.component';
import { HelmetIsComponent } from './components/helmet-is/helmet-is.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';

// Translate & Localize Module
import { TranslateService } from '@ngx-translate/core';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';

// Localize Loader
export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
let url = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/"));
  console.log(`${url}/assets/i18n/locales.json`);
  return new LocalizeRouterHttpLoader(
    translate,
    location,
    settings,
    http,
    `${url}/assets/i18n/locales.json`,
  );
}
const routes: Routes = [{
  path: '',
  component: HomeComponent
}, {
  path: 'video/:prefix/:index',
  component: VideoComponent,
}, {
  path: 'node/:prefix/:index',
  component: NodeFactoryComponent
}, {
  path: 'is',
  // component: IsFactoryComponent,
  children: [
    { path: 'eo', component: EoIsComponent },
    { path: 'ariane6', component: Ariane6IsComponent },
    { path: 'helmet', component: HelmetIsComponent }
  ]
}, {
  path: 'vt/:vt',
  component: VtComponent,
  //data: { skipRouteLocalization: true }
}, { 
  path: 'policy',
  component: PolicyPageComponent 
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
      useHash: true,
      preloadingStrategy: PreloadAllModules
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
    }),
],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { };