<div class="swiper-container swiper-container-{{prefix}}">
  <div class="swiper-wrapper">
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper">
          <section class="items">
            <div class="item item-poe first-node-video-trigger" [ngClass]="isVideoSeen(0) ? 'item-poe-replay' : 'item-poe-play'">
              <h4><span class="sequence-number">0</span>{{'poe-node.subtitle' | translate}}</h4>
            </div>
            <div class="item item-node-1" (click)="navigateToInternalNode(1)">
              <h4><span class="sequence-number">1</span>{{'poe-node.slide-1-title' | translate}}</h4>
            </div>
            <div class="item item-node-2" (click)="navigateToInternalNode(2)">
              <h4><span class="sequence-number">2</span>{{'poe-node.slide-2-title' | translate}}</h4>
            </div>
            <div class="item item-node-3" (click)="navigateToInternalNode(3)">
              <h4><span class="sequence-number">3</span>{{'poe-node.slide-3-title' | translate}}</h4>
            </div>
            <div class="item item-node-4" (click)="navigateToInternalNode(4)">
              <h4><span class="sequence-number">4</span>{{'poe-node.slide-4-title' | translate}}</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(1) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-1">
              <h3 (click)="navigateToInternalNode(0)">{{'poe-node.subtitle' | translate}}</h3>
              <h1>{{'poe-node.slide-1-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/poe/p1.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'poe-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'poe-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Observing_the_Earth/Meteorological_missions/Meteosat" target="_blank">{{'poe-node.slide-1-meteosat' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Observing_the_Earth/Meteorological_missions/MetOp/Overview14" target="_blank">{{'poe-node.slide-1-metop' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Observing_the_Earth/Meteorological_missions" target="_blank">{{'poe-node.slide-1-metereologica-missions' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://climate.esa.int/en/" target="_blank">{{'poe-node.esa-climate-office' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/estec' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESTEC</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node mixed-with-enlight-yellow">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(2) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-2">
              <h3 (click)="navigateToInternalNode(0)">{{'poe-node.subtitle' | translate}}</h3>
              <h1>{{'poe-node.slide-2-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/poe/p2.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'poe-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'poe-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://climate.esa.int/en/" target="_blank">{{'poe-node.esa-climate-office' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Observing_the_Earth/The_Living_Planet_Programme/Earth_Explorers" target="_blank">{{'poe-node.slide-2-earth-explorers' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Overview4" target="_blank">{{'poe-node.slide-2-sentinels' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://blogs.esa.int/eolaunches/" target="_blank">{{'poe-node.slide-2-earth-observation-launches' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://cfs.climate.esa.int/index.html" target="_blank">{{'poe-node.slide-2-climate-from-space-app' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2020/10/Space_for_climate" target="_blank">{{'poe-node.slide-2-space-for-climate' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger enlight-yellow-extra-trigger" (click)="navigateToOtherNodes('bbt', 0)">
              <div class="extra-label enlight-yellow-label">{{'poe-node.building-the-best-tools' | translate}}</div>
              <h6 class="extra-title enlight-yellow-title">{{'poe-node.go-to-this-journey' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(3) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-3">
              <h3 (click)="navigateToInternalNode(0)">{{'poe-node.subtitle' | translate}}</h3>
              <h1>{{'poe-node.slide-3-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/poe/p3.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'poe-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4 class="video-trigger">{{'poe-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="http://www.esa.int/Applications/Observing_the_Earth" target="_blank">{{'poe-node.slide-3-observing-the-earth' | translate}}</a></h4>
            </div>
            <div class="item item-video">
              <h4><a href="http://www.esa.int/ESA_Multimedia/Search?SearchText=%22Earth+from+Space%22&result_type=videos" target="_blank">{{'poe-node.slide-3-earth-from-space' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger is-extra-trigger" [routerLink]="['/is/eo' | localize]">
              <div class="extra-label is-extra-label">{{'poe-node.join-in' | translate}}</div>
              <h6 class="extra-title is-extra-title">{{'poe-node.eo-text' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
    <div class="swiper-slide pure-teal-node mixed-with-trusty-azure">
      <main>
        <div class="main-wrapper" [ngClass]="isVideoSeen(4) ? 'hide-video' : 'show-video'">
          <section class="heading">
            <div class="heading-titles heading-titles-4">
              <h3 (click)="navigateToInternalNode(0)">{{'poe-node.subtitle' | translate}}</h3>
              <h1>{{'poe-node.slide-4-title' | translate}}</h1>
            </div>
          </section>
          <section class="video">
            <div class="video-close">
              <img src="assets/icons/close-white.svg">
            </div>
            <div class="video-trigger" style="background-image: url(../../../assets/video/poe/p4.jpg)">
              <img class="video-play" src="assets/images/single-nodes/commons/video-play-pure-teal.svg">
            </div>
          </section>
          <section class="items">
            <div class="item item-find-out">
              <h3>{{'poe-node.find-out-more' | translate}}</h3>
            </div>
            <div class="item item-reload">
              <h4>{{'poe-node.replay-video' | translate}}</h4>
            </div>
            <div class="item item-video">
              <h4><a href="https://www.esa.int/ESA_Multimedia/Videos/2015/10/Saving_lives_when_disasters_strike#.YAsOQBmWlko.link" target="_blank">{{'poe-node.slide-4-international-charter-space' | translate}}</a></h4>
            </div>
            <div class="item item-webpage">
              <h4><a href="https://www.esa.int/Enabling_Support/Preparing_for_the_Future/Space_for_Earth" target="_blank">{{'poe-node.slide-4-space-for-earth' | translate}}</a></h4>
            </div>
          </section>
          <section class="extra">
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/ecsat' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ECSAT</h6>
            </div>
            <div class="extra-trigger vt-extra-trigger" [routerLink]="['/vt/esrin' | localize]">
              <div class="extra-label vt-extra-label">{{'poe-node.virtual-tour' | translate}}</div>
              <h6 class="extra-title vt-extra-title">ESA ESRIN</h6>
            </div>
            <div class="extra-trigger trusty-azure-extra-trigger" (click)="navigateToOtherNodes('ssa', 0)">
              <div class="extra-label trusty-azure-label">{{'poe-node.safeguarding-space-activities' | translate}}</div>
              <h6 class="extra-title trusty-azure-title">{{'poe-node.disaster-risk-reduction' | translate}}</h6>
            </div>
          </section>
        </div>
      </main>
    </div>
    <!-- node -->
  </div>
  <div class="swiper-button-prev">
    <img src="assets/icons/left-white.svg">
  </div>
  <div class="swiper-button-next">
    <img src="assets/icons/right-white.svg">
  </div>
</div>
