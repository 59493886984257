<button class="vtbtn" (click)="openVt()">
    <img src="assets/images/vt/vt-trigger.gif" alt="">
</button>

<div *ngIf="onGoing" class="vtcontainer">

    <div class="vtstars vtstars-animation-ongoing">
        <div class="black"></div>
        <div *ngFor="let in of counter; let i=index" class="particle"></div>
    </div>

    <div class="vtcontent vtcontent-animation-ongoing">

        <div class="vt-close-button">
            <div class="tool close-trigger" (click)="closeVt()">
                <img src="assets/icons/close-white.svg">
            </div>
        </div>

        <div class="vtcontent-top">
            <h4>ESA 360°</h4>
            <div class="vtcontent-top-img"><img src="assets/images/vt/vtmain-ico.png" alt=""></div>

        </div>
        <div class="vtcontent-bottom ">
            <h5>{{'vt-ui.select-vt' | translate}}</h5>
            <div class="vtcontent-bottom-icons">
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/eac' | localize]">
                    <div class="overlay"></div><img src="assets/images/vt/Esa_eac.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/ecsat' | localize]">
                    <div class="overlay"></div><img src="assets/images/vt/Esa_ecsat.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esac' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esac.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esec' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esec.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esoc' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esoc.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esrin' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esrin.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/estec' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_estec.svg" alt="">
                </a>
                <!-- <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esahq' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_hq.svg" alt="">
                </a> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="onClosing" class="vtcontainer">
    <div class="vtstars vtstars-animation-onclosing">
        <div class="black"></div>
        <div *ngFor="let in of counter; let i=index" class="particle-reverse"></div>
    </div>
    <div class="vtcontent vtcontent-animation-onclosing">

        <div class="vt-close-button">
            <div class="tool close-trigger" (click)="closeVt()">
                <img src="assets/icons/close-white.svg">
            </div>
        </div>

        <div class="vtcontent-top">
            <h4>ESA 360°</h4>
            <div class="vtcontent-top-img"><img src="assets/images/vt/vtmain-ico.png" alt=""></div>

        </div>
        <div class="vtcontent-bottom ">
            <h5>{{'vt-ui.select-vt' | translate}}</h5>
            <div class="vtcontent-bottom-icons">
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/eac' | localize]">
                    <div class="overlay"></div><img src="assets/images/vt/Esa_eac.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/ecsat' | localize]">
                    <div class="overlay"></div><img src="assets/images/vt/Esa_ecsat.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esac' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esac.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esec' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esec.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esoc' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esoc.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esrin' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_esrin.svg" alt="">
                </a>
                <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/estec' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_estec.svg" alt="">
                </a>
                <!-- <a class="image-holder" (click)="closeVt()" [routerLink]="['/vt/esahq' | localize]">
                    <div class="overlay"></div> <img src="assets/images/vt/Esa_hq.svg" alt="">
                </a> -->
            </div>
        </div>
    </div>
</div>